import React from "react";
import { PacmanLoader } from "react-spinners";

const SpinnerPage = () => {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backdropFilter: "blur(3px)",
                    // backgroundColor: "rgba(0,0,30,0.4)",
                }}
            >
                <PacmanLoader color="#8055F8" margin={2} size={30} />
            </div>
        </>
    );
};

export { SpinnerPage };