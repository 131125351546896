
import axios from "@/libs/axios";

const getProject = async (data : any) => {
  return axios.get("/products", data);
};

const AppServices = {
  getProject,
};

export default AppServices;
