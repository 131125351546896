import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AppServices from "@/redux/services/appReducer.service";

interface StateInterface {
    [key: string]: any;
}
const initialState: StateInterface = {
    data: {},
};

export const getProject = createAsyncThunk(
    "/products",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await AppServices.getProject(data);
            return response?.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

const appReducer = createSlice({
    name: "appReducer",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProject.fulfilled, (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        });
    },
});

export default appReducer.reducer;
