import React from 'react';
import ReactDOM from 'react-dom/client';
import './reset.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from '@/redux/store';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SpinnerPage } from './components/spinner';
// const LazyApp = React.lazy(() => import('./App'));
// import LazyApp from './App';
const LazyApp = React.lazy(
  () =>
    new Promise<{ default: React.ComponentType }>((resolve) => {
      setTimeout(() => resolve(import("./App")), 2000);
    })
);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
});
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <React.Suspense fallback={<SpinnerPage />}>
            <LazyApp />
          </React.Suspense>
          <ToastContainer />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
